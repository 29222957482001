// Variables
$m-menu-description-color: #fff;
$form-control-font-size-base: 16px;

@import 'solej_light';

.solej-bordo #newsSlider .item:before,
.solej-bordo .funky-border:before,
.solej-bordo #newsSlider .item:after,
.solej-bordo .funky-border:after {
  display: none;
  background: none;
}

.solej-bordo {
  background: #d7d7d7;
}
.solej-bordo .newsletter i {
  color: white;
}

$primaryColor: #d00e0e;

.section.hours {
  .dl-horizontal {
    width: 400px;
    background: white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    padding: 15px 30px;
    margin-top: 0;

    dt {
      width: 60px;
    }
    dd {
      margin-left: 80px;
      margin-bottom: 0;
    }
  }

  h5, p.muted {
    color: #0a0a0a;
  }
}

.section.funky-border, .carousel {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2);
}


.menu-dropdown.is-affixed {
  z-index: 2;
}


.footer-details {

  .logo-span > a {
    padding: 10px;
    background: white;
    display: inline-block;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  }

  .logo {
    padding: 0px;
    background: transparent;
    box-shadow: none;
  }
}

.navbar .navbar-inner {
  padding-top: 0px;
}


.solej-bordo {


  .navbar-inverse .nav > li > a#checkout_shortcut {
    background: rgba(90, 18, 18, 0.29);
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.3);
    border-radius: 5px;
  }

  .header .logo-container a, .logo-container a .h5, .restaurant-controls, .restaurant-controls a,
  .navigation > ul > li > a {
    font-weight: bold;
    text-shadow: 0 1px #141414;
    color: #f2f2f2;
  }

  .navigation > ul > li > a.active {
    color: white;
  }

  .steps .step {
    transition: all 0.3s ease-in-out;
  }

  .steps .step:hover {
    background-color: rgba(221, 17, 0, 0.6);
    h3, p {color: white; }
  }

  .steps p {
    color:  white;
  }

  .steps .step p {
    color: #0a0a0a;
  }

  .checkout h3:not(.m-checkout__section-title), 
  .content-header h2, 
  .content-header h1 {
    color: white;
  }

  .site-promotions.menu-promotions  {

    & > .text-center.closed, & > .text-center.open {
      color: white;
      text-shadow: 0 1px black;
    }

    a.close {
      position: relative;
      z-index: 25;
      color: black;
      background: white;
      padding: 4px 9px;
      border-radius: 50px;
      text-shadow: none;
    }

  }

  .content-content {
    background: white;
    border-radius: 5px;
  }

  &.dark-text {
    .header .logo-container a, .logo-container a h5, .restaurant-controls, .restaurant-controls a,
    .navigation > ul > li > a {
      color:  #0a0a0a;
      text-shadow: 0 1px white;
    }

    .checkout h3, .content-header h2, .content-header h1 {
      color: #0a0a0a;
      text-shadow: 0 1px white;
    }

    .content-content {
      background: white;
    }

    .navigation > ul > li > a .caret {
      border-top-color: #0a0a0a;
    }

    .steps p, .steps h2 {
      color:  #0a0a0a;
      text-shadow: 0 1px white;
    }

    .site-promotions.menu-promotions > .text-center.open  {
      color: #0a0a0a;
      text-shadow: 0 1px white;
      font-weight: bold;


    }
    .site-promotions.menu-promotions {
      a.close {
        color: #0a0a0a;
        padding: 4px;
        background: white;
        border-radius: 5px;
        text-shadow: 0 1px white;
      }
    }

    .site-promotions .btn { background: white; }

    .section.hours { color: #0a0a0a; }

  }

  &.white-text {
    .site-promotions {
      &__text {
        &,
        h2,
        p {
          color: #fff;
        }
      }
    }
  }

  .navbar-inverse .navbar-inner, .menu-group-header {
    background: $primaryColor url("../images/rustical/daf94f98d687249a992e2866afefa7ee.jpg");
  }

  .navbar-inverse .nav > li > a .item-count {
    background: white;
    color:black;
    text-shadow: none;
  }

  .site-promotions.menu-promotions h2 {
    font-size: 34px;
  }

  .site-promotions ul.unstyled {
    box-shadow: none;
  }


  .site-promotions .promotion-image img {
    border: 1px solid $primaryColor;
  }

  .tab-content {
    overflow: inherit;
  }

  .add-button .btn {
    background: #460606;
  }

  .steps {

    .badge {
      left: 15px;
      top: 0;
      border-radius: 0;
      border: none;
      color: white;
      background: transparent;
      z-index: 1;
    }

    .step {
      background: white;
      padding: 50px 0px;
      i {
        color: $primaryColor;
      }

      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    }

    .step:after {
      background: none;
      display: none;
    }

    .step:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0px;
      border-width: 50px 50px 0px 0px;
      border-style: solid;
      border-color: $primaryColor transparent transparent transparent;
      display: block;
      width: 0;
    }

    .img-circle {
      border: 1px solid $primaryColor;
    }

    h3 {
      color: $primaryColor;
    }
    p {
      font-weight: normal;
    }

  }

  .box-column {
    color: #0a0a0a;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    background-color: transparent;
    background-image: radial-gradient(circle at 50% 1%, transparent 50px, white 0);
  }

  .box-column img {
    background: transparent;
  }

  .referral .img-circle {
    background: $primaryColor;
    border-color: white;
    box-shadow: 0 0 0 3px $primaryColor;
  }

  .newsletter {


    .container {
      background: white;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);

      & > div {
        border: none;
      }
    }


    .img-circle {
      border: none;
      background: $primaryColor;
    }

    input {
      border: 1px solid $primaryColor;
    }

    h3 {
      color: #0a0a0a;
    }

    .social-icons a {

      background: $primaryColor;
      border: none;
      i {
        color: white;
      }
    }


  }

  .footer-details {
    background: rgba(0,0,0,0.85);
  }
  .footer-copy {
    background: rgba(0,0,0,0.3);
  }

}
